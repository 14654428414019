
import { uploadImage } from '@/api/universal/file'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: '封面'
    },
    filesrc: {
      type: String,
      default: 'course-series/cover'
    }
  },
  setup: (props, { emit }) => {
    const fileList = ref<any[]>([])
    const addFile = () => {
      if (props.value !== '') {
        fileList.value.push({
          url: props.value
        })
      }
    }
    addFile()
    watch(() => props.value, addFile)
    return {
      fileList,
      uploadImage: (file: File) => {
        uploadImage(props.filesrc, file).then(r => {
          emit('update:value', r)
        })
      },
      delCover: () => {
        emit('update:value', '')
      }
    }
  }
})
